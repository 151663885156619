import firebase from '../../Firebase.js';
import SubscriptionPlanModel from '../model/SubscriptionPlanModel'

const subscriptionPlans = ()=> {
    return  new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection("SubscriptionPlans").where("active", "==", true).get()
        .then((querySnapshot) => {
            var plans = [] 
            querySnapshot.forEach(async (doc)=> {
                const priceSnap = await doc.ref.collection('prices').get()
                const priceDoc = priceSnap.docs[0]
                let price = priceDoc.data()
                let data = doc.data() 
                var imageUrl = data.images.length > 0 ? data.images[0] : "" 
                var planModel = new SubscriptionPlanModel(doc.id, data.name, price.unit_amount, data.description, imageUrl, price.interval, price.currency)
                plans.push(planModel)
                if (plans.length == querySnapshot.size) {
                    resolve(plans)
                }
            })
           
        }).catch((error) => {
            reject(error)
        })
    })
}

const subscribePlan = (id) => {
    console.log("check out")
}

export default { subscriptionPlans }