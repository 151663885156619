import React from 'react'
import { Button, Box } from '@material-ui/core'

function CreateItem(props) {

    const style = {
        cursor: 'copy'
    }

    return (
        <Button onClick={props.onClick}>
            <Box flexGrow={1} style={style} px={1} py={1} border={0}>
                {props.children}
            </Box>
        </Button>
    )
}

export default CreateItem
