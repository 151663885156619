import React, { useMemo, useContext, useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import HomePage from './page/HomePage'
import VideoPage from './page/VideoPage'
import EditorPage from './page/EditorPage'
import UploadPage from './page/UploadPage'
import SubscriptionPage from './page/SubscriptionPage'
import NotFoundPage from './page/NotFoundPage'
import TermsAndServicePage from './page/TermsAndServicePage'
import PrivacyPage from './page/PrivacyPage'
import Footer from './common/Footer'
import NavBar from './common/NavBar'
import AppDialog from './AppDialog'
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { themeContext } from './context/ThemeContext'
import { reactLocalStorage } from 'reactjs-localstorage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_qrQG8OqMhv5H4v0bSRu4UjfC00CGyrAKPb');

function App() {

  var darkModeSetting = reactLocalStorage.getObject('darkMode');
  const themeManager = useContext(themeContext)
  themeManager.darkTheme = darkModeSetting ? darkModeSetting.isDark : false
  const [dark, setDark] = useState(themeManager.darkTheme)

  const updateTheme = (isDark) => {
    themeManager.darkTheme = isDark
    reactLocalStorage.setObject('darkMode', { isDark: themeManager.darkTheme });
    setDark(themeManager.darkTheme)
  }

  var oakkTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          // support only light mode for now, to reduce testing effort 
          // type: prefersDarkMode ? 'dark' : 'light',
          type:  dark ? 'dark' : 'light',
          primary: {
            main: dark ? '#7FFFD4' : '#FF4500'
          }
        }
      }),
    [dark]
  );
  oakkTheme = responsiveFontSizes(oakkTheme);
  return (
    <MuiThemeProvider theme={oakkTheme}>
      <themeContext.Provider value={{darkTheme: themeManager.darkTheme, updateDarkTheme: updateTheme}}>
      <CssBaseline />
      <Elements stripe={stripePromise}>
      <AppDialog>
      <Router>
        <div className="App">
          <NavBar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/create" component={UploadPage} />
            <Route exact path="/videos/:id" component={VideoPage} />
            <Route exact path="/videos/edit/:id" component={EditorPage} />
            <Route exact path="/features" component={SubscriptionPage} />
            <Route exact path="/404" component={NotFoundPage}/>
            <Route exact path="/TermsAndService" component={TermsAndServicePage}/>
            <Route exact path="/Privacy" component={PrivacyPage}/>
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </div>
      </Router>
      </AppDialog>
      </Elements>
      </themeContext.Provider>
    </MuiThemeProvider>
  )
}

export default App
