import firebase from '../../Firebase.js'
import DateUtil from '../util/DateUtil'
import DraggableItemModel from '../model/DraggableItemModel.js'

const getByVideoStorageId = (videoStorageId) => {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection("videos").where("videoStorageId", "==", videoStorageId).get()
        .then((querySnapshot) => {
            var result = []; 

            querySnapshot.forEach((video) => {
                result.push({"id" : video.id, ...video.data()})
            })
            resolve(result)
        }).catch((error) => {
            reject(error)
        })
    })
}

// firestore helper methods 
const createVideo = (videoEntry) => {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection("videos").add(videoEntry).then(() => {
            resolve(videoEntry)
        }).catch((error) => {
            reject(error)
        })
    })
}

// retrieve overlays associated with video 
const getOverlaysById = (video) => {
    return new Promise((resolve, reject) => {
        if (video == null) { reject(new Error("Video not found")) }
        const db = firebase.firestore() 
        db.collection("overlays").doc(video.videoStorageId).get().then((querySnapshot) => {
            const data = querySnapshot.data()
            if (data !== undefined && data.draggableItems !== undefined) {
                const result = data.draggableItems.map((item) => {
                    return new DraggableItemModel(item.id, item.type, item.text, item.link, item.x, item.y, item.translateX, item.translateY, false)
                })
                resolve(result)
            } else {
                resolve([])
            }
        })
    })
}

// store overlays associated with video 
const setOverlaysById = (video, draggableItemModels) => {
    var overlays = draggableItemModels.filter(item => item.hidden === false)
    var draggableItems = overlays.map((item) => ({
        id : item.id, 
        type : item.type,
        text : item.text,
        link : item.link,
        x : item.x,
        y : item.y,
        translateX: item.translateX,
        translateY: item.translateY,
        videoStorageId : video.videoStorageId
    }))
    var entry = {
        lastModified : DateUtil.now(), 
        draggableItems : draggableItems,
        videoStorageId: video.videoStorageId 
    } 

    return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection("overlays").doc(video.videoStorageId).set(entry).then(() => {
            resolve() 
        }).catch((error) => {
            reject(error)
        })
    })
}

const setVideoById = (id, video) => {
    if (id == null) return 
    return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        var vid = video 
        vid.lastModified = DateUtil.now()
        db.collection("videos").doc(id).set(vid).then(() => {
            resolve()
        }).catch((error) => {
            reject(error)
        })
    })
}

const deleteVideo = (id) => {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection("videos").doc(id).get().set({"archieved" : true}).then(() => {
            resolve()
        }).catch((error) => {
            reject(error)
        })
    })
}

export default { createVideo, deleteVideo, setVideoById, getByVideoStorageId, getOverlaysById, setOverlaysById }