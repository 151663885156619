const Configs = { }

const OverlayConfig = {
    INITIAL_X : '0', 
    INITIAL_Y : '0'
}

const StaticRoutes = {
    HOME: '/',
    CREATE: '/create',
    NOT_FOUND: '/404', 
    UPGRADE: '/upgrade'
}

const StandardPadding = {
    PX: 2,
    PY: 2
}

const LargePadding = {
    PX: 4,
    PY: 4
}

const ContentWidth = {
    SM: 12,
    MD: 6
}

const Panels = {
    height: 600
}

export default Configs
export { OverlayConfig, StaticRoutes, StandardPadding, LargePadding, ContentWidth, Panels }