import React, { useState, useContext, useEffect, useRef } from 'react'
import { userContext } from '../context/UserContext'
import { FormControl, List, ListItem, Button, Box, Grid, Typography, Paper, Avatar } from '@material-ui/core'
import { StandardPadding, ContentWidth, LargePadding, Panels } from '../Configs'
import { dialogContext } from '../context/DialogContext'
import DialogModel from '../model/DialogModel'
import LoginPanel from '../common/LoginPanel'
import ProductUtil from '../util/ProductUtil'
import MessageUtil from '../util/MessageUtil'
import { CardElement, useStripe, useElements     } from '@stripe/react-stripe-js'

function SubscriptionPage() {

    const dialogManager = useContext(dialogContext)
    const userManager = useContext(userContext)
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [customer, setCustomer] = useState(null)
    const stripe = useStripe();
    const elements = useElements();

    const checkoutSubscription = async (e, id) => {
        debugger;
        if (!stripe || !elements) { showDialog("There is an issue, please try again later."); return }
        
         // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        })

        if (error) {
            console.log("[error]", error)
        } else {
            console.log("[PaymentMethod]", paymentMethod)
        }


    }

    function showDialog(msg) {
        const dialog = new DialogModel("Message", msg, "Ok")
        dialog.callback = ()=> { console.log("") }
        dialogManager.updateDialogMsg(dialog)
    }

    useEffect(() => { 
        ProductUtil.subscriptionPlans().then((plans)=> {
            setSubscriptionPlans(plans)
        }).catch((error)=> {
            MessageUtil.messagePopup(error)
        })
    }, []) 

    return(
        <userContext.Consumer>
        {(userManager) => (
        userManager.user ?
        <div>
        <Box flexGrow={1} align="center" py={StandardPadding.PY}>
            <Typography variant="h2" color="primary">Features</Typography>
        </Box>
        <Box align="center" py={StandardPadding.PY} px={StandardPadding.PX} mx="auto">
            <Grid container direction="row" justity="center" alignItems="flex-start" spacing={4}>
                { 
                    subscriptionPlans.map((plan) => (
                        <Grid item xs={ContentWidth.SM} xs={ContentWidth.SM} md={4}>
                        <Paper>
                            <Box py={StandardPadding.PY} px={StandardPadding.PX} height={Panels.height}>
                                <Avatar src={plan.imageUrl}/>
                                <br/>
                                <Typography variant="h4" color="primary">{plan.title}</Typography>
                                <br/>
                                <Typography variant="h2" color="primary">{plan.formattedPrice()}<Typography variant="subtitle2" color="primary">/{plan.recurrence}</Typography></Typography>
                                <br/>
                                <Box py={LargePadding.PY} align="center">
                               
                                    {plan.notes().map((note, i) => (
                                        <Box style={{"justifyContent": "center"}}>
                                            <Typography variant="subtitle1">{note}</Typography><br/>
                                        </Box>
                                    ))}
                                    
                                </Box>
                            </Box>
                            <Box py={StandardPadding.PY}>
                                <Button size="large" variant="contained" color="primary" onClick={(e) => {checkoutSubscription(e, plan.id)}}>Get Started</Button>
                            </Box>
                        </Paper>
                        </Grid>
                    ))  
                }
            </Grid>
        </Box>
        </div>
        :
        <Box flexGrow={1} align="center" py={StandardPadding.PY}>
            <Grid item sm={ContentWidth.SM} md={ContentWidth.MD}>
                <Typography variant="h2" color="primary">
                    Features
                </Typography>
                <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                    <LoginPanel title={"Please sign in manage subscriptions"} />
                </Box>
            </Grid>
        </Box>
        )}
        </userContext.Consumer>
    )
}

export default SubscriptionPage