import React from 'react'
import { Box } from '@material-ui/core'

const DropCanvas = React.forwardRef((props, ref) => {

    const style= {
        position: 'relative'
    }

    return (
        <Box style={style} px={0} py={0} ref={ref}>
            {props.children}
        </Box>
    )
})

export default DropCanvas