import React from 'react'
import { Paper, Typography, Box } from '@material-ui/core'

function TemplateLibraryGallery(props) {
    return (
        <Box px={props.px} pb={props.pb} pt={props.pt}>
            <Paper variant="outlined">
                <Box px={4} py={4}>
                    <Typography variant="h5">
                        Free Video Library
                    </Typography>
                </Box>
                <Box px={4} pb={4}>
                    <Typography variant="subtitle1" color="primary">
                        coming soon...
                    </Typography>
                </Box>
            </Paper>
        </Box>
    )
}

export default TemplateLibraryGallery