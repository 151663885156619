import React, { useState, useEffect, useContext, useRef } from 'react'
import { Redirect } from "react-router-dom"
import VideoUtil from '../util/VideoUtil'
import MessageUtil from '../util/MessageUtil'
import StyleUtil from '../util/StyleUtil'
import { Button, Link, Box, Grid, Paper, Typography, TextField } from '@material-ui/core'
import { Player } from 'video-react'
import DraggableItem from '../common/dnd/DraggableItem'
import CreateItem from '../common/dnd/CreateItem'
import DropCanvas from '../common/dnd/DropCanvas'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import HttpOutlinedIcon from '@material-ui/icons/Http'
import GifIcon from '@material-ui/icons/Gif'
import { StaticRoutes, StandardPadding, ContentWidth } from '../Configs'
import '../../css/video-react.css'
import DraggableItemTypes from '../common/dnd/DraggableItemTypes'
import DraggableItemModel from '../model/DraggableItemModel'
import { dialogContext } from '../context/DialogContext'
import DialogModel from '../model/DialogModel'
import { isTouchingBottomMargin } from '../util/BoundingCheckUtil'
import uuid from 'react-uuid'


function EditorPage(props) {

    const dialogManager = useContext(dialogContext)
    const [title, setTitle] = useState('')
    const [notFound, setNotFound] = useState(false)
    const [video, setVideo] = useState(null)
    const shareUrl = window.location.href.replace("edit/", "")
    const [overlays, setOverlays] = useState([])
    const dropCanvasRef = useRef(null)

    const saveAndPublish = (e) => {
        VideoUtil.setOverlaysById(video, overlays).then(()=> {
            const updatedVid = video
            updatedVid.published = true
            setVideo(updatedVid)
            return VideoUtil.setVideoById(updatedVid.id, updatedVid)
        }).then(()=> {
            const dialog = new DialogModel("Message", "Video Published", "Ok")
            dialog.callback = ()=> { console.log("") }
            dialogManager.updateDialogMsg(dialog)
        }).catch((error) => {
            const dialog = new DialogModel("Message", error, "Ok")
            dialog.callback = ()=> { console.log("") }
            dialogManager.updateDialogMsg(dialog)
        })
    }

    const dragStop = (e, data, id) => {
        e.stopPropagation()
        e.preventDefault()
        const canvas = dropCanvasRef.current
        const dragItem = data.node
        if (isTouchingBottomMargin (dragItem, canvas)) {
            removeElement(id)
        } else {
            updateOverlay(data, canvas, id)
        }
    }

    const handleDrag = (e, ui) => {
        e.stopPropagation()
        e.preventDefault()
    }

    // update position since user moved it
    // we need the position info to re-initiate overlay positions later 
    const updateOverlay = (data, canvas, id) => {
        var maxWidth = canvas.clientWidth
        var maxHeight = canvas.clientHeight
        var newList = []
        for (var i = 0; i < overlays.length; i++) {
            var item = overlays[i]
            if (item.id === id) {
                item.translateX = data.lastX 
                item.translateY = data.lastY
            }
            newList = newList.concat(item)
        }
        setOverlays(newList)
    }

    // remove the corresponding node 
    const removeElement = (id) => {
        // filter out the element that matches id in our list     
        var newList = []
        for (var i = 0; i < overlays.length; i++) {
            var item = overlays[i]
            if (item.id === id) {
                item.hidden = true
            }
            newList = newList.concat(item)
        }
        setOverlays(newList)
    }

    const addOverlay = (type) => {
        var newList = [] 
        var model = {}
        const modelId = uuid()
        switch (type) {
            case DraggableItemTypes.TEXT:
                model = new DraggableItemModel(modelId, type, modelId, "", 25, 75, 0, 0, false)
                newList = overlays.concat(model)
                setOverlays(newList)
                break
            case DraggableItemTypes.LINK:
                model = new DraggableItemModel(modelId, type, modelId, "CUSTOM LINK", "http://oakk.wisetreeapps.com", 25, 75, 0, 0, false)
                newList = overlays.concat(model)
                setOverlays(newList)
                break
            case DraggableItemTypes.GIF:
                console.log("add gif")
                break
            default: break
        }
    }

    const updateTitle = (e) => {
        setTitle(e.target.value)
    }

    const saveTitle = () => {
        if (video == null) return
        var vid = video
        vid.title = title
        VideoUtil.setVideoById(video.id, vid).then(() => {
            setVideo(vid)
        }).catch((error) => {
            MessageUtil.messagePopup(error)
        })
    }

    useEffect(() => { 
    }, [overlays])

    // one off by declaring an empty dependency array 
    useEffect(() => {
        console.log("getByVideoStorageId")
        VideoUtil.getByVideoStorageId(props.match.params.id).then((result) => { 
            if (!result.empty && result.length > 0) {
                const vid = result[0]
                setTitle(vid.title)
                setVideo(vid) 
                return VideoUtil.getOverlaysById(vid)
            } else {
                setNotFound(true)
                return VideoUtil.getOverlaysById(null)
            }
        }).then((newList) => {
            setOverlays(newList)
        }).catch((error) => {
            MessageUtil.messagePopup(error)
        })

    }, [props.match.params.id])

   
    if (notFound) return (<Redirect to={StaticRoutes.NOT_FOUND}/>)
    if (video == null) return (null)
    return (
        <Box flexGrow={1} align="center">
            <Grid item sm={ContentWidth.SM} md={ContentWidth.MD}>
                <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                    <Paper variant="outlined" mx="auto">
                        <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                            <Box px={StandardPadding.PX} py={StandardPadding.PY} textAlign="center">
                                <form>
                                    <TextField fullWidth label="Title" value={title}
                                        onChange={updateTitle}
                                        onBlur={saveTitle}
                                        inputProps={StyleUtil.textAlignStyle("center")} />
                                </form>
                            </Box>
                            <DropCanvas ref={dropCanvasRef}>
                                <Player fluid={true} autoPlay={true} loop={true}>
                                    <source src={video.url} toggleFullscreen={false}/>
                                </Player>
                                {
                                    overlays.map((draggableItemModel, i) => (
                                        <DraggableItem disabled={false} key={draggableItemModel.modelId} item={draggableItemModel}
                                                        dragStop={(e, data) => { dragStop(e, data, draggableItemModel.id)}} handleDrag={(e, ui)=> { handleDrag(e, ui, draggableItemModel.id)}} />
                                    ))
                                }
                            </DropCanvas>
                            <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                                <Typography variant="caption">drag to bottom to remove</Typography>
                            </Box>
                        </Box>
                    </Paper>

                </Box>
                <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                    <Paper variant="outlined" mx="auto">
                        <Box display="flex" px={StandardPadding.PX} py={StandardPadding.PY} justifyContent="center">
                            <Grid container spacing={2} mx="auto" flexGrow={1} justify="space-evenly">
                                <Grid item key="1" py={0}>
                                    <CreateItem onClick={()=>addOverlay(DraggableItemTypes.TEXT)}>
                                        <TextFieldsIcon fontSize="large" />
                                    </CreateItem>
                                </Grid>
                                {/* <Grid item key="2" py={0}>
                                    <CreateItem onClick={() => addOverlay(DraggableItemTypes.LINK)}>
                                        <HttpOutlinedIcon fontSize="large" />
                                    </CreateItem>
                                </Grid>
                                <Grid item key="3" py={0}>
                                    <CreateItem onClick={() => addOverlay(DraggableItemTypes.GIF)}>
                                        <GifIcon fontSize="large" />
                                    </CreateItem>
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Box px={StandardPadding.PX} pb={StandardPadding.PY}>
                            <Typography variant="caption">shareable url: <Link href={shareUrl}>{shareUrl}</Link></Typography>
                        </Box>
                    </Paper>
                </Box>
                <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                    <Paper variant="outlined" mx="auto">
                    <Box display="flex" px={StandardPadding.PX} py={StandardPadding.PY} justifyContent="center">
                        <Grid container spacing={2} mx="auto" flexGrow={1} justify="space-evenly">
                            <Grid item key="b" py={0}>
                                <Button variant="contained" color="primary" onClick={saveAndPublish}>Publish</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    </Paper>
                </Box>
            </Grid>
        </Box>
    )
}

export default EditorPage