import firebase from '../../Firebase.js';
import uuid from 'react-uuid'

// firebase storage helper methods
const uploadVideo = (videoFile, setProgressCallback) => {
    return new Promise((resolve, reject) => {
        const vid = uuid();
        const videoStorageRef = firebase.storage().ref('videos/' + vid)
        const uploadTask = videoStorageRef.put(videoFile);
        uploadTask.on("state_changed",
            snapshot => {
                if (setProgressCallback != null) {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    setProgressCallback(progress)
                }
            },
            error => {
                reject(error);
            },
            () => {
                firebase.storage().ref("videos").child(vid).getDownloadURL().then(storageUrl => {
                    resolve({videoStorageId: vid, videoStorageUrl: storageUrl});
                }).catch((error) => {
                    reject(error)
                });
            }
        )
    });
}

export default { uploadVideo }