import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import VideoUtil from '../util/VideoUtil'
import MessageUtil from '../util/MessageUtil'
import DateUtil from '../util/DateUtil'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Player } from 'video-react'
import { StaticRoutes, StandardPadding, ContentWidth } from '../Configs'
import StaticItem from '../common/dnd/StaticItem'
import DropCanvas from '../common/dnd/DropCanvas'
import '../../css/video-react.css'

function VideoPage(props) {

    const [video, setVideo] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const [overlays, setOverlays] = useState([])
    const dropCanvasRef = useRef(null)

    // one off by declaring an empty dependency array 
    useEffect(() => {
        console.log("getByVideoStorageId")
        VideoUtil.getByVideoStorageId(props.match.params.id).then((result) => { 
            if (!result.empty && result.length > 0) {
                const vid = result[0]
                setVideo(vid)
                return VideoUtil.getOverlaysById(vid)
            } else {
                setNotFound(true)
                return VideoUtil.getOverlaysById(null)
            }
        }).then((newList) => {
            setOverlays(newList)
        }).catch((error) => {
            MessageUtil.messagePopup(error)
        })

    }, [props.match.params.id])

    useEffect(() => { 
    }, [setOverlays])

    if (notFound) return (<Redirect to={StaticRoutes.NOT_FOUND}/>)
    if (video == null) return (null)
    return (
        <Box flexGrow={1} align="center">
            <Grid item sm={ContentWidth.SM} md={ContentWidth.MD}>
                <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                    <Typography variant="body1">{video.title}</Typography><br />
                    <Paper variant="outlined" mx="auto">
                        <DropCanvas ref={dropCanvasRef}>
                            <Player fluid={true} autoPlay={true}>
                                <source src={video.url} />
                            </Player>
                            {
                                overlays.map((draggableItemModel, i) => (
                                    <StaticItem disabled={true} key={draggableItemModel.modelId} item={draggableItemModel}/>
                                ))
                            }
                        </DropCanvas>
                        <Box px={StandardPadding.PX} pb={StandardPadding.PY} textAlign="center">
                            <Typography variant="caption">{video.author}</Typography><br/>
                            <Typography variant="caption">created: {DateUtil.fromNow(video.created)}</Typography><br/>
                            <Typography variant="caption">last modified: {DateUtil.fromNow(video.lastModified)}</Typography>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Box>
    )
}

export default VideoPage