import React from 'react'
import { Card, CardContent, CardActions, Link, Paper, Typography, Box, Grid } from '@material-ui/core'
import VideoStatus from './VideoStatus'
import '../../css/Gallery.css'
import { LargePadding, StandardPadding } from '../Configs'
import { Player, BigPlayButton } from 'video-react'

function Gallery(props) {

    const playerStyle = {
        overflow: "hidden"
    }

    // if videoList is empty or null, don't display this component 
    if (props.videoList === null || props.videoList.length === 0) return (null)
    return (
        <Box px={props.px} pb={props.pb} pt={props.pt}>
            <Paper variant="outlined">
                <Box px={LargePadding.PX} py={LargePadding.PY}>
                    <Typography variant="h5">
                        {props.title}
                    </Typography>
                </Box>
                <Box px={LargePadding.PX} py={LargePadding.PY}>
                    <Grid container spacing={4} mx="auto" flexGrow={0}>
                        {props.videoList.map((video) => (
                            <Grid item key={video.videoStorageId} xs={12} sm={6}>
                                <Link href={"/videos/edit/" + video.videoStorageId}>
                                    <Card variant="elevation">
                                        <CardContent>
                                            <Box>
                                                <Player style={playerStyle} muted fluid={true} autoPlay={false} currentTime={2000}>
                                                    <BigPlayButton position="center" />
                                                    <source src={video.url} />
                                                </Player>
                                            </Box>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Box px={0} py={0} justifyContent="center" flexGrow={1}>
                                                <Typography variant="body1" component="p">{video.title}</Typography>
                                            </Box>
                                        </CardActions>
                                        <Box px={StandardPadding.PX} pb={StandardPadding.PY}>
                                            <VideoStatus published={video.published} />
                                        </Box>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    )
}

export default Gallery