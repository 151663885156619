import React from 'react';
import { Link, Box, Typography } from '@material-ui/core';
import { LargePadding } from '../Configs'


function Footer() {
    return (
        <Box mx="auto" flexGrow={1} align="center" py={LargePadding.PY}>
            <Typography variant="caption" color="textPrimary" mx="auto">
                &copy;2020 Wisetree Solutions Pty Ltd
            </Typography>
            <br/>
            <Typography variant="caption">
                <Link href="/termsandservice">Terms and Service</Link> | <Link href="/privacy">Privacy</Link>
            </Typography>
        </Box>

    )
}

export default Footer;