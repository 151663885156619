import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'; 
import 'firebase/database';

const config = {
    apiKey: "AIzaSyD7FBWOvcEHK321I7ovdyHxo6TbTFqGuuA",
    authDomain: "oakk.wisetreeapps.com",
    databaseURL: "https://oakk-30847.firebaseio.com",
    projectId: "oakk-30847",
    storageBucket: "oakk-30847.appspot.com",
    messagingSenderId: "719307388270",
    appId: "1:719307388270:web:0b73ccc6e7f3a43c2e3d7f",
    measurementId: "G-8HE3R0X1G7"
};
firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export default firebase; 