import React, { useState } from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import Draggable from 'react-draggable'
import DraggableItemTypes from '../dnd/DraggableItemTypes'
import '../../../css/Draggable.css'
// import { OverlayConfig } from '../../Configs'

const DraggableItem = React.forwardRef((props) => {

    const [position, setPosition] = useState(null)
    const handleDrag = (e, position) => {
        const { x, y } = position
        setPosition({x: x, y: y})
    }

    const renderItem = (item) => {
        switch (item.type) {
            case DraggableItemTypes.TEXT:
                return <Typography variant="h5" color="primary">{item.text}</Typography>
            case DraggableItemTypes.LINK:   
                return <Link target="_blank" href={item.link} variant="h5" color="primary">{item.text}</Link>
            default:
                return 
        }
    }

    const style = (item) => {
        const css = {
            display: "inline-block",
            position: "absolute",
            cursor: props.disabled ? "pointer": "move"
        }
        return css
    }

    return (
        <Draggable disabled={props.disabled} id={props.modelId} border={0} onStop={props.dragStop} onDrag={handleDrag} bounds="parent" position={{x: props.item.translateX, y: props.item.translateY}}>
            <Box style={style(props.item)} hidden={props.item.hidden} zIndex="modal" flexGrow={1}
                px={1} py={1} border={props.disabled ? 0 : 1} top={props.item.y + '%'} left={props.item.x + '%'}>
                <Box flexGrow={1}>
                    {renderItem(props.item)}
                </Box>
            </Box>
        </Draggable>
    )
})

export default DraggableItem
