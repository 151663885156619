const BoundingCheckUtil = { }

// helper method to detect if child touching the bottom margain of giving parent boundary
const isTouchingBottomMargin = (child, parent)=> {
    if (child == null) { return false }
    if (parent == null) { return false }
    const childRect = child.getBoundingClientRect()
    const parentRect = parent.getBoundingClientRect()
    const diff = childRect.bottom - parentRect.bottom
    return  Math.abs(diff) <= 1 ? true : false
}

export default BoundingCheckUtil
export { isTouchingBottomMargin }