import React, { useState, useEffect, useContext } from 'react'
import { Typography, Box, Grid, Container } from '@material-ui/core'
import MessageUtil from '../util/MessageUtil'
import { userContext } from '../context/UserContext'
import firebase from '../../Firebase.js';
import { useHistory } from 'react-router-dom';
import Gallery from '../common/Gallery'
import TemplateLibraryGallery from '../common/TemplateLibraryGallery'
import VideoUploadPanel from '../common/VideoUploadPanel'
import LoginPanel from '../common/LoginPanel'
import VideoUtil from '../util/VideoUtil'
import VideoStorageUtil from '../util/VideoStorageUtil'
import DateUtil from '../util/DateUtil'
import { StandardPadding, ContentWidth } from '../Configs'

function UploadPage() {

    const defaultThumbnail = "https://firebasestorage.googleapis.com/v0/b/oakk-30847.appspot.com/o/images%2Ffavicon-logo-2.png?alt=media&token=0959f6c2-a789-4457-843d-235f6b74a9b4"
    const history = useHistory();
    const userManager = useContext(userContext)
    const [videoList, setVideoList] = useState([])
    const [videoFile, setVideoFile] = useState(null)
    const [progress, setProgress] = useState(0) 

    const handleFileSelect = async (e) => {
        const video = e.target.files[0];
        setVideoFile(video)
    }

    // handle whenever file is select
    useEffect(()=> {

        const uploadVideo = () => {
            VideoStorageUtil.uploadVideo(videoFile, setProgress).then((result) => {
                // now that we got our uploaded video URL, save data to firestore 
                console.log(result)
                const videoEntry = {
                    author : userManager.user.email,
                    url : result.videoStorageUrl,
                    videoStorageId : result.videoStorageId,
                    created : DateUtil.now(),
                    archived : false, 
                    published : false, 
                    thumbnailUrl : defaultThumbnail, 
                    title : videoFile.name, 
                    lastModified : DateUtil.now()
                }
                return videoEntry
            }).then((videoEntry)=> {
                return VideoUtil.createVideo(videoEntry)
            }).then((videoEntry)=> {
                history.push("/videos/edit/" + videoEntry.videoStorageId)
            }).catch((error)=>{
                MessageUtil.messagePopup(error)
            })
        }

        if (videoFile == null) return 
        uploadVideo() 
    }, [videoFile, userManager, history])

    // user's video gallery
    useEffect(() => {
        if (userManager.user == null) return
        var videosRef = firebase.firestore().collection("videos")
        videosRef = videosRef.where("author", "==", userManager.user.email)
        videosRef = videosRef.where("archived", "==", false)
        videosRef.limit(30)
        const unsub = videosRef.onSnapshot(snapshot => {
            let fetchedList = [];
            snapshot.forEach(doc => {
                const data = doc.data();
                data.id = doc.id;
                fetchedList.push(data);
            })
            setVideoList(fetchedList)
        })

        // clean up function 
        return ()=> {
            unsub() 
        }
    }, [userManager])

    return (
        <Container>
            <Box flexGrow={1} align="center" py={StandardPadding.PY}>
                <Grid item sm={ContentWidth.SM} md={ContentWidth.MD}>
                    <Typography variant="h2" color="primary">
                        Simple Online Video Editing
                    </Typography>
                    <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                        <userContext.Consumer>
                            {(userManager) => (
                                userManager.user ?
                                <Box>
                                    <VideoUploadPanel handleFileSelect={handleFileSelect} progress={progress} px={StandardPadding.PX} py={StandardPadding.PY} />
                                    <Gallery title={"Select from cloud"} videoList={videoList} px={StandardPadding.PX} py={StandardPadding.PY} />
                                    <TemplateLibraryGallery px={StandardPadding.PX} py={StandardPadding.PY} />
                                </Box>
                                :
                                <Box>
                                    <LoginPanel title={"Please sign in to start accessing your video cloud"} />
                                </Box>
                            )}
                        </userContext.Consumer>
                    </Box>
                </Grid>
            </Box>
        </Container>
    )
}

export default UploadPage