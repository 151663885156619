import React from 'react'
import { Hidden, Link, Paper, Typography, Box, Grid, Container, Button } from '@material-ui/core'
import instruction_image_1 from '../../images/instruction_image_1.jpg'
import instruction_image_2 from '../../images/instruction_image_2.jpg'
import instruction_image_3 from '../../images/instruction_image_3.jpg'
import { StaticRoutes, ContentWidth, StandardPadding, LargePadding } from '../Configs'

function HomePage() {

    return (
        <Container>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                    <Typography variant="h2" color="primary" mx="auto" >
                        The Power of Video Personalization with Interactivity
                        </Typography>
                    <Typography variant="subtitle1" color="textPrimary" mx="auto">
                        8X improvement in campaign performances by blending video, personalization and interactivity.<br />
                            The easy way to create stunning videos, add subtitles and grow your audience.<br />Get started now with ZERO cost
                        </Typography>
                    <Box py={LargePadding.PY}>
                        <Button size="large" href={StaticRoutes.CREATE} variant="contained" color="primary" my={4}>Get started</Button>
                    </Box>
                </Grid>
            </Box>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Grid container direction="row" justity="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Paper variant="outlined">
                            <Box px={StandardPadding.PX} pt={LargePadding.PY}>
                                <Hidden mdUp>
                                    <img src={instruction_image_1} alt="image1" />
                                </Hidden>
                            </Box>
                            <Box px={StandardPadding.PX} py={LargePadding.PY}>
                                <Typography variant="body1">
                                    Easy to use. An interface that's perfect for marketers and influencers. It's so simple that you can use it immediately without training. And if you do ever have a question, our team is on hand to help!
                                    </Typography>
                                <Typography variant="h6">
                                    <Link href={StaticRoutes.CREATE} color="primary">
                                        Try it now
                                        </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Box px={StandardPadding.PX} py={StandardPadding.PY}>
                            <Hidden smDown>
                                <img src={instruction_image_1} alt="image1" />
                            </Hidden>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Grid container direction="row" justity="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Box px={StandardPadding.PX} py={LargePadding.PY}>
                            <Hidden smDown>
                                <img src={instruction_image_2} alt="image2" />
                            </Hidden>
                        </Box>
                    </Grid>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Paper variant="outlined">
                            <Box px={StandardPadding.PX} pt={LargePadding.PY}>
                                <Hidden mdUp>
                                    <img src={instruction_image_2} alt="image2" />
                                </Hidden>
                            </Box>
                            <Box px={StandardPadding.PX} py={LargePadding.PY}>
                                <Typography variant="body1">
                                    Simple yet powerful Editing tools that reduce complex tasks to just a few clicks. Say goodbye to clunky video software and hello to one-click editing online.
                                    </Typography>
                                <Typography variant="h6">
                                    <Link href={StaticRoutes.CREATE} color="primary">
                                        Try it now
                                        </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Grid container direction="row" justity="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Paper variant="outlined">
                            <Box px={StandardPadding.PX} pt={LargePadding.PY}>
                                <Hidden mdUp>
                                    <img src={instruction_image_3} alt="image3" />
                                </Hidden>
                            </Box>
                            <Box px={StandardPadding.PX} py={LargePadding.PY}>
                                <Typography variant="body1">
                                    All your files in one place Keep all your content, fonts, logos, and color pallets in one place so you are always on-brand. Create a workspace to keep your content organized.
                                    </Typography>
                                <Typography variant="h6">
                                    <Link href={StaticRoutes.CREATE} color="primary">
                                        Try it now
                                        </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                        <Box px={StandardPadding.PX} py={LargePadding.PY}>
                            <Hidden smDown>
                                <img src={instruction_image_3} alt="image3" />
                            </Hidden>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Typography variant="h5">
                    Increase your conversions - Grow your audience Learn how to make great content, that converts
                    </Typography>
            </Box>
            <Box flexGrow={1} align="center" py={LargePadding.PY}>
                <Grid item xs={ContentWidth.SM} md={ContentWidth.MD}>
                    <Typography variant="h2" color="primary">
                        Features
                        </Typography>
                    <Box py={LargePadding.PY}>
                        <Paper variant="outlined">
                            <Box py={LargePadding.PY}>
                                <Typography variant="h6">Smart Creativity with Scalability</Typography>
                            </Box>
                            <Box pb={LargePadding.PY}>
                                <Typography variant="h6">Personalize for Millions</Typography>
                            </Box>
                            <Box pb={LargePadding.PY}>
                                <Typography variant="h6">Design for Performance</Typography>
                            </Box>
                            <Box pb={LargePadding.PY}>
                                <Typography variant="h6">Get insights on every experience</Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box py={LargePadding.PY}>
                        <Button size="large" href={StaticRoutes.CREATE} variant="contained" color="primary" my={StandardPadding.PY}>Get started</Button>
                    </Box>
                </Grid>
            </Box>
        </Container>
    )
}

export default HomePage