import React from 'react'
import { Box, Typography } from '@material-ui/core'

function VideoStatus(props) {
    return (
        props.published ?
            <Box>
                <Typography variant="caption" color="primary">published</Typography>
            </Box>
            :
            <Box>
                <Typography variant="caption">pending</Typography>
            </Box>
    )
}

export default VideoStatus