export default class SubscriptionPlanModel {
    constructor(id, title, price, descriptions, imageUrl, recurrence, currency) {
        this.id = id
        this.title = title
        this.price = price
        this.descriptions = descriptions
        this.imageUrl = imageUrl 
        this.recurrence = recurrence
        this.currency = currency

        this.formattedPrice = ()=> {
            var dollars = this.price / 100;
            dollars = dollars.toLocaleString("en-US", {style:"currency", currency: this.currency});
            return dollars
        }

        this.notes = ()=> {
            var notes = [] 
            
            if (title === "Basic") {
                notes.push("Basic widgets to enhance your video")
                notes.push("Access to basic video analytics of visits")
                notes.push("Automatically scaled hosting platform")
            } else if (title === "Artist") {
                notes.push("Fancy widget pack to enhance your video")
                notes.push("Collect likes on your video")
                notes.push("Access to video analytics")
                notes.push("Automatically scaled hosting platform")
                notes.push("Creative widgets to collect money")
            } else if (title === "Enterprise") {
                notes.push("Enterprise-Styled widgets pack")
                notes.push("Collect likes on your video ")
                notes.push("Access to video analytics")
                notes.push("Automatically scaled hosting platform")
            }

            return notes
        }
    }
}