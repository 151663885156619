export default class DraggableItemModel {
    constructor(id, type, text, link, x, y, translateX, translateY, hidden) {
        this.id = id 
        this.type = type
        this.text = text
        this.link = link
        this.x = x
        this.y = y 
        this.translateX = translateX
        this.translateY = translateY
        this.hidden = hidden
    } 
}